import { useEffect, useState, useRef } from 'react';
import { Paper, Typography } from '@mui/material';
import { Row } from '../NewCustomers';
import ModalToInvoice from 'app/main/Invoices/Invoices/Modals/ModalToInvoice';
import Button from '@mui/material/Button';
import store from 'app/store';
import client from 'client';
import { changeCurrency } from '../../../../constants';

export default function ClientsData({
  handleDrawerToggle,
  infoCustomer,
  rechargedAfterChanges,
  handleApplyPaymentInvoice,
  setShowInvoiceInApply,
  accumulateInvoiceApply,
  setAccumulateInvoiceApply,
  infoPayments,
  tickets,
  setTickets,
  setInfo,
  info,
  openModalViewInvoice,
  setInfoCustomer,
  customerId,
  tabValue,
  setInfoPayments,
  searchByJobNo,
  searchByInvoiceNo,
  prop_data,
  paymentsearch,
  date1,
  date2,
  hasGlobalSearched,
  hasGlobalTicketSearched,
  selectedCustomer,
  globalResults,
  setTabValue,
  setInvoiceDate1,
  setInvoiceDate2,
  invoiceDate1,
  invoiceDate2,
  getPerCustomerDate,
  handleDateSearch,
  page,
  setPage,
  hasNextPage,
  setHasNextPage,
  listLoading,
  setListLoading,
  globalSearch,
  globalTicketSearch,
  sorting,
  setSorting,
  SortColumn,
  setDisableList,
  grandTotalHauling,
  grandTotalWait,
  grandTotal,
  grandTotalBalance,
  setGrandTotalHauling,
  setGrandTotalWait,
  setGrandTotal,
  setGrandTotalBalance,
  grandTotalMaterial,
  setGrandTotalMaterial,
  setGrandTotalAgent,
  grandTotalAgent,
}) {
  const openModalEditInvoice = useRef(null);

  //this fires on customer select
  useEffect(async () => {
    if (customerId) {
      setPage(1);
      setSorting({
        invoice: { active: false, order: 'asc' },
        job: { active: false, order: 'asc' },
        dateUpdated: { active: false, order: 'asc' },
        dueDate: { active: false, order: 'asc' },
        totalHauling: { active: false, order: 'asc' },
        totalWait: { active: false, order: 'asc' },
        material: { active: false, order: 'asc' },
        grandTotal: { active: false, order: 'asc' },
        balancePending: { active: false, order: 'asc' },
        agent: { active: false, order: 'asc' },
        current: { column: '', order: '' },
        flag: false,
      });
      setInfoCustomer([]);
      setInfoPayments([]);
      setInvoiceDate1('');
      setInvoiceDate2('');
      setHasNextPage(true);

      if (hasGlobalTicketSearched == false) {
        await getPerCustomer();
      } else {
        //look here
        //console.log('compacctNumSub',compacctNumSub)
        const insert_into_setInfoSub = [];

        const new_globalResults = [...globalResults];

        await Promise.all(
          new_globalResults.map(async (res) => {
            if (res.customer_id == customerId) {
              let unpaidInvoices = [];
              let paidInvoices = [];

              await Promise.all(
                res.invoices.map((inv) => {
                  const bal = changeCurrency(inv.balance);
                  if (bal > 0) {
                    unpaidInvoices.push(inv);
                  } else {
                    paidInvoices.push(inv);
                  }
                })
              );
              if (unpaidInvoices.length > 0) {
                res.invoices = unpaidInvoices;

                insert_into_setInfoSub.push(...unpaidInvoices);

                setTabValue(0);
              } else {
                res.invoices = paidInvoices;

                insert_into_setInfoSub.push(...paidInvoices);
                setTabValue(1);
              }
            }
          })
        );

        setInfoCustomer(insert_into_setInfoSub);
      }
    }
  }, [customerId]);

  //this fires when they change tabs
  useEffect(async () => {
    if (customerId) {
      setPage(1);

      setInfoCustomer([]);
      setInfoPayments([]);
      setInvoiceDate1('');
      setInvoiceDate2('');
      setHasNextPage(true);

      if (tabValue != 2) {
        if (hasGlobalTicketSearched == false) {
          await getPerCustomer();
        } else {
          if (tabValue == 0) {
            //look here
            //console.log('compacctNumSub',compacctNumSub)
            const insert_into_setInfoSub = [];

            const new_globalResults = [...globalResults];

            await Promise.all(
              new_globalResults.map(async (res) => {
                if (res.customer_id == customerId) {
                  let unpaidInvoices = [];

                  await Promise.all(
                    res.invoices.map((inv) => {
                      const bal = changeCurrency(inv.balance);
                      if (bal > 0) {
                        unpaidInvoices.push(inv);
                      }
                    })
                  );
                  if (unpaidInvoices.length > 0) {
                    res.invoices = unpaidInvoices;

                    insert_into_setInfoSub.push(...unpaidInvoices);
                  }
                }
              })
            );
            setInfoCustomer(insert_into_setInfoSub);
          }
          if (tabValue == 1) {
            //look here
            //console.log('compacctNumSub',compacctNumSub)
            const insert_into_setInfoSub = [];

            const new_globalResults = [...globalResults];

            await Promise.all(
              new_globalResults.map(async (res) => {
                if (res.customer_id == customerId) {
                  let paidInvoices = [];

                  await Promise.all(
                    res.invoices.map((inv) => {
                      const bal = changeCurrency(inv.balance);
                      if (bal == 0) {
                        paidInvoices.push(inv);
                      }
                    })
                  );
                  if (paidInvoices.length > 0) {
                    res.invoices = paidInvoices;

                    insert_into_setInfoSub.push(...paidInvoices);
                  }
                }
              })
            );
            setInfoCustomer(insert_into_setInfoSub);
          }
        }
      }

      if (tabValue == 2) {
        await getPaymentPerCustomer();
      }

      setSorting({
        invoice: { active: false, order: 'asc' },
        job: { active: false, order: 'asc' },
        dateUpdated: { active: false, order: 'asc' },
        dueDate: { active: false, order: 'asc' },
        totalHauling: { active: false, order: 'asc' },
        totalWait: { active: false, order: 'asc' },
        material: { active: false, order: 'asc' },
        grandTotal: { active: false, order: 'asc' },
        balancePending: { active: false, order: 'asc' },
        agent: { active: false, order: 'asc' },
        current: { column: '', order: '' },
        flag: false,
      });
    }
  }, [tabValue]);

  const listInnerRef = useRef();
  const [scrollX, setScrollX] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);

  const state = store.getState();

  useEffect(async () => {
    //if dates are cleared load all invoices based on tab
    if (!invoiceDate1 && !invoiceDate2 && !globalTicketSearch && !globalSearch) {
      setPage(1);
      setHasNextPage(true);
      if (tabValue == 0 || tabValue == 1) {
        getPerCustomer();
      }

      if (tabValue == 2) {
        getPaymentPerCustomer();
      }
    }
  }, [invoiceDate1, invoiceDate2]);

  useEffect(async () => {
    if (!hasGlobalTicketSearched && !hasGlobalSearched) {
      setPage(1);
      setHasNextPage(true);
      if (
        searchByJobNo.length ||
        searchByInvoiceNo.length ||
        paymentsearch.length ||
        date1 ||
        date2
      ) {
        if (tabValue == 0 || tabValue == 1) {
          getPerCustomer();
        }

        if (tabValue == 2) {
          getPaymentPerCustomer();
        }
      }

      if (
        !searchByJobNo.length &&
        !searchByInvoiceNo.length &&
        !paymentsearch.length &&
        !date1 &&
        !date2
      ) {
        if (tabValue === 0 || tabValue === 1) {
          getPerCustomer();
        }
        if (tabValue == 2) {
          getPaymentPerCustomer();
        }
      }
    }
  }, [searchByJobNo, searchByInvoiceNo, paymentsearch, date1, date2]);

  const getPaymentPerCustomer = async (page = 1) => {
    const user = prop_data ? prop_data : state?.loadz_auth?.AuthReducer?.user;
    if (customerId && user) {
      setDisableList(true);
      setTableLoading(true);
      const result = await client.get(
        `/payments/get-payments/customer/${customerId}/${
          prop_data ? prop_data?.compacctnum : user?.company?.compacctnum
        }/0/${paymentsearch.length ? paymentsearch : 'null'}/${
          searchByInvoiceNo ? searchByInvoiceNo : 'null'
        }/${date1 ? date1 : 'null'}/${date2 ? date2 : 'null'}`,
        {
          params: {
            page,
          },
        }
      );
      let new_data = infoPayments;
      page == 1
        ? (new_data = result.data)
        : result?.data?.payment && !Array.isArray(result?.data?.payment)
        ? new_data?.payment?.invoice_no_search.push(...result?.data?.payment?.invoice_no_search) &&
          new_data?.payment?.ref_conf_search.push(...result?.data?.payment?.ref_conf_search)
        : result?.data?.payment
        ? new_data?.payment.push(...result?.data?.payment)
        : new_data;
      const data = new_data;
      if (
        result.data?.payment?.length > 0 ||
        result.data?.payment?.invoice_no_search.length > 0 ||
        result.data?.payment?.ref_conf_search.length > 0
      ) {
        setPage(page);
        setInfoPayments(data);
      }
      if (
        !result?.data?.payment ||
        result.data?.payment?.length == 0 ||
        (!Array.isArray(result?.data?.payment) &&
          result.data?.payment?.invoice_no_search?.length == 0 &&
          result.data?.payment?.ref_conf_search?.length == 0)
      ) {
        setHasNextPage(false);
        page == 1 ? setInfoPayments([]) : null;
      }
      console.log('customer invoice::getPayment customer', data);
      setInfoCustomer([]);
      setTableLoading(false);
      setDisableList(false);
    }
  };

  const getPerCustomer = async (page = 1) => {
    const user = prop_data ? prop_data : state?.loadz_auth?.AuthReducer?.user;
    const status = tabValue == 1 ? 0 : 1;
    if (customerId && user) {
      setDisableList(true);
      setTableLoading(true);
      const result = await client.get(
        `/invoices/customer/${
          prop_data ? prop_data.compacctnum : user?.company?.compacctnum
        }/${customerId}/${status}/${searchByInvoiceNo.length ? searchByInvoiceNo : 'null'}/${
          searchByJobNo.length ? searchByJobNo : 'null'
        }`,
        {
          params: {
            page,
          },
        }
      );

      let new_data = [...infoCustomer];
      const only_invoices = [];
      if (page == 1) {
        result.data.map(async (job) => {
          job.invoices.map(async (invoice) => {
            only_invoices.push(invoice);
          });
        });
        if (result.data.length) {
          setGrandTotal(result.data[0].totals.grandTotal);
          setGrandTotalBalance(result.data[0].totals.grandTotalBalance);
          setGrandTotalHauling(result.data[0].totals.grandTotalHauling);
          setGrandTotalWait(result.data[0].totals.grandTotalWait);
          setGrandTotalMaterial(result.data[0].totals.grandTotalMaterial);
          setGrandTotalAgent(result.data[0].totals.grandTotalAgentCommission);
        }
        if (sorting.flag) {
          SortColumn(sorting.current.order, sorting.current.column, only_invoices);
        } else {
          setInfoCustomer(only_invoices);
        }
      } else {
        result.data.map(async (job) => {
          job.invoices.map(async (invoice) => {
            only_invoices.push(invoice);
          });
        });
        new_data.push(...only_invoices);
        if (sorting.flag) {
          SortColumn(sorting.current.order, sorting.current.column, new_data);
        } else {
          setInfoCustomer(new_data);
        }
      }

      if (
        result.data?.length > 0 ||
        result.data?.invoice_no_search?.length > 0 ||
        result.data?.job_no_search?.length > 0
      ) {
        setPage(page);
      }
      if (
        result.data?.length == 0 ||
        (result.data?.invoice_no_search?.length == 0 && result.data?.job_no_search?.length == 0)
      ) {
        setHasNextPage(false);
      }
      setInfoPayments([]);
      setTableLoading(false);
      setDisableList(false);
    }
  };

  if (
    infoCustomer.length > 0 ||
    !Array.isArray(infoCustomer) ||
    infoPayments?.payment?.length > 0 ||
    (infoPayments?.payment && !Array.isArray(infoPayments?.payment))
  ) {
    return (
      <>
        <ModalToInvoice
          getPerCustomer={getPerCustomer}
          prop_data={prop_data}
          tickets={tickets}
          openModal={openModalEditInvoice}
          info={info}
          rechargedAfterChanges={rechargedAfterChanges}
        />

        <Row
          grandTotalHauling={grandTotalHauling}
          grandTotalWait={grandTotalWait}
          grandTotal={grandTotal}
          grandTotalBalance={grandTotalBalance}
          grandTotalMaterial={grandTotalMaterial}
          grandTotalAgent={grandTotalAgent}
          sorting={sorting}
          setSorting={setSorting}
          SortColumn={SortColumn}
          invoiceDate1={invoiceDate1}
          invoiceDate2={invoiceDate2}
          setInvoiceDate1={setInvoiceDate1}
          setInvoiceDate2={setInvoiceDate2}
          handleDateSearch={handleDateSearch}
          getPerCustomerDate={getPerCustomerDate}
          hasGlobalSearched={hasGlobalSearched}
          hasGlobalTicketSearched={hasGlobalTicketSearched}
          tabValue={tabValue}
          listLoading={listLoading}
          hasNextPage={hasNextPage}
          page={page}
          getPerCustomer={getPerCustomer}
          getPaymentPerCustomer={getPaymentPerCustomer}
          prop_data={prop_data}
          row={infoCustomer}
          openModalViewInvoice={openModalViewInvoice}
          setTickets={setTickets}
          setInfo={setInfo}
          openModalEditInvoice={openModalEditInvoice}
          handleApplyPaymentInvoice={handleApplyPaymentInvoice}
          setShowInvoiceInApply={setShowInvoiceInApply}
          accumulateInvoiceApply={accumulateInvoiceApply}
          setAccumulateInvoiceApply={setAccumulateInvoiceApply}
          infoPayments={infoPayments}
          tableLoading={tableLoading}
        ></Row>
      </>
    );
  } else {
    return (
      <div className='flex flex-col flex-1 items-center justify-center p-24 '>
        <Paper className='rounded-full p-48 md:p-64 shadow-xl'>
          <img
            src='assets/images/logos/inspection.png'
            className='w-128 m-32'
            color='secondary'
          ></img>
        </Paper>
        <Typography variant='h6' className='mt-24 mb-12 text-32 font-700' color='black'>
          Customers Info
        </Typography>
        <Typography
          className='hidden md:flex px-16 pb-24 text-16 text-center'
          color='textSecondary'
        >
          Choose a customer to view their invoice details.
        </Typography>

        <Button
          onClick={() => handleDrawerToggle()}
          variant='outlined'
          color='third'
          className='flex md:hidden'
        >
          Select Customer to View Invoices
        </Button>
      </div>
    );
  }
}
//TODO improved messages.
